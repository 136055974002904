/* .App {
  font-family: Arial, sans-serif;
  text-align: center;
  margin: 20px;
}

.container {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}

.form {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 300px;
  text-align: left;
}

input {
  width: 100%;
  margin: 10px 0;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  margin: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #218838;
}

.resultados {
  margin-top: 20px;
}

.resultados p {
  font-size: 18px;
  margin: 10px 0;
} */

.custom-gradient {
  background-color: linear-gradient(to bottom, #1b93c3, #ffffff) !important;
  margin-bottom: 270px;
}

.bottom-cta-area {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%; /* Ocupa toda a largura da tela */
  background-color: #fff; /* Cor de fundo */
  text-align: center; /* Centraliza o conteúdo */
  padding: 25px 0; /* Espaçamento interno */
  box-shadow: 0 -20px 5px rgba(175, 175, 175, 0.2); /* Sombra opcional */
  z-index: 1000; /* Garante que fique sobre outros elementos */
  height: 100px;
}
.bottom-cta-area a {
text-decoration: none;
}

.cta {
  background-color: #002868;
  padding: 15px;
  color: #fff;
  border-radius: 15px;
}
.btn-cta {
  background-color: #fff;
  align-items: center;
  border: 0 solid #fff;
  border-radius: 100px;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  height: 48px;
  justify-content: center;
  margin: 0;
  padding: 2px 20px;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  transition: all 0.3s;
}
.text-area {
  display: flex;
  flex-direction: column;
}
.action-area {
  display: flex;
  align-items: center;
  justify-content: center;
}

.txt-economizar {
  font-size: 21px;
  font-weight: 800;
  color: #04f7f7;
}

@media (max-width: 768px) {
  .bottom-cta-area{
      max-height: 80px!important;
  }
  .bottom-cta-area a {
      font-size: 12px; 
      padding: 10px 10px !important;
      flex: 1;
  }
  .container-cust{
      padding-right: 0px!important;
      padding-left: 0px!important;
  }
  .hide-mobile{
      display: none;
  }
}


/* 
    Itens restantes:
    aplicar a formula na calculadora clt x pj: https://online.crcsp.org.br/portal/noticias/noticia.asp?c=6664#
    enviar email na vera 
    
*/